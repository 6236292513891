import { initContract } from '@ts-rest/core';
import {
   PaginatedWelkinTasksResponse,
   searchTaskQuery,
   TaskTemplate,
   uuidSchema,
   welkinBatchStatus,
   welkinCommentSchema,
   welkinCreateTaskSchema,
   WelkinTask,
   welkinUpdateTaskSchema,
   welkinWatcherSchema,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const tasks = c.router(
   {
      getTasks: {
         method: 'GET',
         path: '/tasks',
         query: searchTaskQuery,
         responses: {
            200: c.type<PaginatedWelkinTasksResponse>(),
         },
      },

      getTask: {
         method: 'GET',
         path: '/tasks/:id',
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      createTask: {
         method: 'POST',
         path: '/tasks',
         body: welkinCreateTaskSchema,
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      updateTask: {
         method: 'PATCH',
         path: '/tasks/:id',
         body: welkinUpdateTaskSchema,
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      addComment: {
         method: 'PATCH',
         path: '/tasks/:id/comment',
         body: welkinCommentSchema,
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      removeComment: {
         method: 'DELETE',
         path: '/tasks/:id/comment/:commentId',
         pathParams: z.object({
            id: uuidSchema,
            commentId: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      addWatcher: {
         method: 'PATCH',
         path: '/tasks/:id/watcher',
         body: welkinWatcherSchema,
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      removeWatcher: {
         method: 'DELETE',
         path: '/tasks/:id/watcher/:watcherId',
         pathParams: z.object({
            id: uuidSchema,
            watcherId: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinTask>(),
         },
      },

      batchStatusUpdate: {
         method: 'PATCH',
         path: '/tasks/batchStatus',
         body: welkinBatchStatus,
         responses: {
            200: c.type<unknown>(),
         },
      },

      getTemplateNames: {
         method: 'GET',
         path: '/tasks/templateNames',
         responses: {
            200: c.type<TaskTemplate[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
