import {
   createRuleSchema,
   updateRuleSchema,
   type EligibilityRule,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const soharEligibilityRules = c.router(
   {
      createRule: {
         method: 'POST',
         path: '/sohar-eligibility-rules',
         body: createRuleSchema,
         responses: {
            200: c.type<unknown>(),
         },
      },

      updateRule: {
         method: 'PATCH',
         path: '/sohar-eligibility-rules/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: updateRuleSchema,
         responses: {
            200: c.type<unknown>(), //TODO: unknown
         },
      },

      deleteRule: {
         method: 'DELETE',
         path: '/sohar-eligibility-rules/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<Partial<unknown>>(), //TODO: unknown
         },
      },

      getRule: {
         method: 'GET',
         path: '/sohar-eligibility-rules/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<EligibilityRule>(),
         },
      },

      getRules: {
         method: 'GET',
         path: '/sohar-eligibility-rules',
         responses: {
            200: c.type<EligibilityRule[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
